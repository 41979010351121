import {RUM} from '@eda-restapp/logger'

export function initRumScenarios() {
  let isLoggedIn = false
  const pathname = window.location.pathname

  try {
    const persistAuth = localStorage.getItem('persist:auth')
    if (persistAuth) {
      isLoggedIn = JSON.parse(persistAuth).oauthToken !== 'null'
    }
  } catch (error) {
    const safeError = error instanceof Error ? error : new Error(`initRumScenarios getLoggedIn NonError: ${error}`)
    RUM.logError({error: safeError})
  }

  if (isLoggedIn) {
    if (pathname === '/home') {
      RUM.setScenariosTimeStamp('core_init_home_page')
    }
    if (pathname === '/active') {
      RUM.setScenariosTimeStamp('core_init_active_page')
    }
    RUM.setScenariosTimeStamp('core_init')
  } else {
    RUM.setScenariosTimeStamp('core_init_auth_page')
  }
}
